import React from 'react';
import { Text } from '@chakra-ui/react';

const formatDate = (date, formatPattern) => {
    const options = {};

    // Personalização simples de formato baseado no padrão fornecido
    switch (formatPattern) {
        case 'dd/MM/yyyy':
            return date.toLocaleDateString('pt-BR');
        case 'MMMM do, yyyy':
            options.month = 'long';
            options.day = 'numeric';
            options.year = 'numeric';
            break;
        case 'MM/dd/yyyy':
            return date.toLocaleDateString('en-US');
        default:
            options.year = 'numeric';
            options.month = 'short';
            options.day = '2-digit';
    }

    return date.toLocaleDateString(undefined, options);
};

const DateDisplay = ({
    date = new Date(),  // Data padrão é a data atual
    formatPattern = 'dd/MM/yyyy', // Padrão de formatação
    textColor = 'black', // Cor do texto (personalizável)
    fontSize = 'md',     // Tamanho da fonte (personalizável)
    fontWeight = 'normal' // Peso da fonte (personalizável)
}) => {
    const parsedDate = new Date(date);

    // Verifica se a data é válida
    if (isNaN(parsedDate)) {
        return <Text color="red.500">Data inválida</Text>;
    }

    return (
        <Text color={textColor} fontSize={fontSize} fontWeight={fontWeight}>
            {formatDate(parsedDate, formatPattern)}
        </Text>
    );
};

export default DateDisplay;

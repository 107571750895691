// src/components/LocacaoCard.jsx
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { Image, Text, IconButton, Button, Card, CardHeader, CardBody, CardFooter, ButtonGroup, Heading } from '@chakra-ui/react';
import PopoverUi from '../../ui/Popover';

const LocacaoCard = ({ locacao, onView, onEdit, onDelete, ...rest }) => (

	<Card align={'center'} textAlign={'center'}>
		<CardHeader>
			{locacao.photo !== 'null' && 
				<Image 
				objectFit='cover' 
				src={`http://localhost:5000${locacao.photo}`} 
				alt={locacao.name} 
				fallbackSrc='https://placehold.co/300x100' borderRadius={'lg'} />
			}
		</CardHeader>

		<CardBody>
			<Heading size='md'>{locacao.name}</Heading>
			<Text pt='2' fontSize='sm'>{locacao?.address?.city}, {locacao?.address?.state}</Text>
		</CardBody>

		<CardFooter>
			<ButtonGroup>
				<IconButton onClick={() => onView(locacao)} icon={<ViewIcon />} />
				<IconButton onClick={() => onEdit(locacao)} icon={<EditIcon />} />
				<PopoverUi element={<IconButton icon={<DeleteIcon />} />}>
					<Text>Tem certeza que deseja excluir esta locação?</Text>
					<Button onClick={() => onDelete(locacao)}>Sim</Button>
				</PopoverUi>
			</ButtonGroup>
		</CardFooter>
	</Card>

);

export default LocacaoCard;

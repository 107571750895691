import React from 'react';
import { Box, Heading, HStack, Text, SimpleGrid, Tag, VStack } from '@chakra-ui/react';

const CronTeam = ({ team, ...rest }) => {
  return (
    <Box {...rest} p={10} boxShadow='md' bgColor='white'>
      <Heading size="md">Equipe</Heading>
      <SimpleGrid columns={5} spacing={4} mt={2}>
        {team.map(member => (
          <VStack key={member._id} p={4} borderWidth={1} borderRadius="md" shadow="md" width="100%">
            <Text fontSize="lg" fontWeight="bold">{member.name}</Text>
            <Tag fontSize="xs">{member.function}</Tag>
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CronTeam;

import LoginForm from "../components/loginForm";
import LoginLayout from "./LoginLayout";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "../misc/siteConfig";

import { loginUser } from "../services/backend/UserService";
import useSignIn from 'react-auth-kit/hooks/useSignIn';

import { useToastContext } from "../context/ToastContext";
import { useNavigate } from "react-router-dom";

function Login() {
    const signIn = useSignIn();
    const {showToast} = useToastContext();
    const navigate = useNavigate();
    const handleSubmit = async (auth) => {
        try {
			const response = await loginUser(auth)
			const { user, token, refresh } = response

			if(signIn({
                auth: {
                    token: token,
                    type: 'Bearer'
                },
                refresh: refresh,
                userState: user
            })) {
                navigate('/dashboard/home');
            }
		} catch (error) {
			showToast({
				description: error.message,
				status: 'error'
			})
		}
    }

    return (
        <LoginLayout>
            <Helmet>
                <title>{SITE_NAME} &#8208; Login</title>
            </Helmet>
            <LoginForm onSubmit={handleSubmit} />
        </LoginLayout>
    )
}

export default Login
import { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';

import { Helmet } from "react-helmet"
import { SITE_NAME } from "../misc/siteConfig"
import { getScriptById } from "../services/backend/ScriptService"
import { getScenesAll } from "../services/backend/ScenesService";
import { useToastContext } from '../context/ToastContext'
import { Box, Center, Spinner, VStack, Text } from "@chakra-ui/react"
import ScriptInfo from "../components/public/scriptInfo";
import SceneTable from "../components/public/sceneTable";


const Public = () => {
    const { id: _id } = useParams()
    const [script, setScript] = useState({})
    const [scenes, setScenes] = useState([])
    const { showToast } = useToastContext()

    useEffect(() => {
        const getScript = async (id) => {
            try {
                let recoverScript = await getScriptById(_id)
                let listScenes = await getScenesAll({ script: _id })

                setScenes(listScenes.docs)
                setScript(recoverScript)
            } catch (error) {
                showToast({
                    title: 'Não foi possível encontrar o script',
                    description: error.message,
                    status: 'error'
                })
            }
        }

        if (_id) {
            getScript(_id)
        }
    }, [_id])

    return (
        <Center height='100vh'>
            <Helmet>
                <title>{SITE_NAME} &#8208; {`${script?.title}`}</title>
            </Helmet>
            {script.shared ? (
                <VStack>
                    <ScriptInfo script={script} />
                    <SceneTable scenes={scenes} />
                </VStack>
            ) : (
                <VStack>
                    <Spinner />
                    <Text>Compartilhamento desativado!</Text>
                </VStack>
            )}
        </Center>
    )
}

export default Public
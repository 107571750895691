import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Homepage() {
    return (
        <HStack display='flex' alignItems='center' width='100%' bgColor='red' p={4} justifyContent='space-between'>
            <Box>
                <Link>aaaa</Link>
                <Link>BBBB</Link>
                <Link>CCCC</Link>
            </Box>
        </HStack>
    )
}

export default Homepage;
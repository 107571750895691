import { Routes, Route } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Scripts from './pages/Scripts';
import Layout from "./pages/Layout";
import Customer from "./pages/Customer";
import Scenes from "./pages/scripts/Scenes";
import Equipamento from "./pages/Equipamentos";
import Locacao from './pages/Locacao';
import Cron from "./pages/Cron";
import Homepage from "./pages/Homepage";
import Config from "./pages/Config";
import Login from "./pages/Login";
import CronCreate from './pages/cron/create'
import CronView from "./pages/cron/view";

import Register from "./pages/Register";
import Public from "./pages/Public";
import Premium from "./pages/Premium";
import Checkout from "./pages/Checkout";

import Planos from "./pages/admin/Planos";

import AuthOutlet from '@auth-kit/react-router/AuthOutlet'

function App() {
  return (
    <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registrar" element={<Register />} />
        <Route path="/public/roteiro/:id" element={<Public />} />
        <Route path="/checkout" element={<Checkout />} />
        
        <Route element={<AuthOutlet fallbackPath="/login" />} >

          <Route path="/dashboard" element={<Layout />}>
            <Route path="home" element={<Dashboard />} />
            <Route path="roteiro" element={<Scripts />} />
            <Route path="roteiro/cenas/:id" element={<Scenes />} />
            <Route path="cronograma" element={<Cron />} />
            <Route path="cronograma/criar" element={<CronCreate />} />
            <Route path="cronograma/editar/:id" element={<CronCreate />} />
            <Route path="cronograma/ver/:id" element={<CronView />} />

            <Route path="clientes" element={<Customer />} />
            <Route path="equipamentos" element={<Equipamento />} />
            <Route path="locacoes" element={<Locacao />} />
            <Route path="configuracoes" element={<Config />} />
            <Route path="premium/:show" element={<Premium />} />
            <Route path="premium" element={<Premium />} />

            <Route path="admin/planos" element={<Planos />} />
          </Route>
        
        </Route>
    </Routes>
  );
}

export default App;

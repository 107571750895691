import { Icon, VStack, Link, Tooltip, styled } from "@chakra-ui/react";
import { HiLogout } from "react-icons/hi";

import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import { logoutUser } from "../../services/backend/UserService";
import { useToastContext } from "../../context/ToastContext";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function UserSession({ ...rest }) {
    const { showToast } = useToastContext()
    const navigate = useNavigate()
    const signOut = useSignOut()
    const isAuthenticated = useIsAuthenticated()

    const handleLogout = async () => {
        try {
            const logout = await logoutUser();
            const isSignedOut = signOut();
            navigate('/login')
        } catch (error) {
            // Caso algo dê errado com o logout
            console.error('Erro ao tentar fazer logout:', error);
        }
    }

    return (
        <VStack {...rest}>
            <Tooltip label='Logout'>
                <Link onClick={handleLogout} fontSize="sm">
                    <Icon boxSize={6} as={HiLogout} />
                </Link>
            </Tooltip>
        </VStack>
    )
}

export default UserSession;
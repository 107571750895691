// src/components/LocacaoModal.jsx
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Table,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';

const LocacaoModal = ({ isOpen, onClose, locacao }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <Image 
          src={`http://localhost:5000${locacao.photo}`}
          fallbackSrc='https://placehold.co/400x200'
          alt={locacao.name} 
          width="100%" 
          height={300}/>
        
        <ModalHeader>{locacao.name}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Th>Nome</Th>
                <Td>{locacao.name}</Td>
              </Tr>
              <Tr>
                <Th>Descrição</Th>
                <Td>{locacao?.description}</Td>
              </Tr>
              <Tr>
                <Th>Endereço</Th>
                <Td>
                  {locacao?.address?.streetname}, {'Nº '+locacao.address?.streetnumber || 'S/N'}, {locacao.address?.hood}, {locacao.address?.city} - {locacao.address?.state}
                </Td>
              </Tr>
              <Tr>
                <Th>Representante</Th>
                <Td>{locacao.rep?.name}</Td>
              </Tr>
              <Tr>
                <Th>Telefone</Th>
                <Td>{locacao.rep?.phone}</Td>
              </Tr>
              <Tr>
                <Th>Email</Th>
                <Td>{locacao.rep?.email}</Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LocacaoModal;

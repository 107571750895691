import {  HStack, SimpleGrid } from "@chakra-ui/react"
import PlanCard from "./planCard"

const SubscriptionForm = ({ onSubmit, plans, activePlan }) => {

    return (
        <HStack>
            <SimpleGrid templateColumns="repeat(3, 400px)" gap={4}>
                {plans && plans.map((plan) => (
                    <PlanCard key={plan._id} plan={plan} activePlan={activePlan} button_label={'Assinar'} callback={onSubmit} />
                ))}
            </SimpleGrid>
        </HStack>
    )
}

export default SubscriptionForm
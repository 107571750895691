import {
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    VStack,
    Text,
    Link,
    ButtonGroup,
    FormErrorMessage
} from "@chakra-ui/react"
import { useState } from "react"

function RegisterForm({ onSubmit, ...rest }) {
    const [formData, setFormData] = useState({
        fullname: '',
        password: '',
        passwordConfirm: '',
        email: ''
    })
    const [formErrors, setFormErrors] = useState({})
    const [show, setShow] = useState(false)

    const handleClick = () => setShow(!show)

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })

        if (formErrors[name]) {
            setFormErrors({
                ...formErrors,
                [name]: ''
            })
        }
    }

    const handleClear = () => {
        setFormData({
            fullname: '',
            password: '',
            passwordConfirm: '',
            email: ''
        })
        setFormErrors({})
    }

    const validateForm = () => {
        var errors = {}

        // Validate password
        if (!formData.password) errors.password = 'Senha de acesso é obrigatória'
        if (formData.password.length < 8) errors.password = 'A senha precisa ter pelo menos 8 caracteres'

        // Validate email
        if (!formData.email) errors.email = 'Preencha com um email'
        if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Digite um email válido!'

        // Validate passwordConfirm
        if (!formData.passwordConfirm) errors.passwordConfirm = 'Por favor, confirma a senha'
        if (formData.password !== formData.passwordConfirm) errors.passwordConfirm = 'As senhas não são iguais'

        // Validate fullname
        if (!formData.fullname) errors.fullname = 'Preencha com seu nome completo'
        if (!formData.fullname.includes(' ')) errors.fullname = 'Preencha com seu nome completo (nome e sobrenome)'

        setFormErrors(errors)
        return Object.keys(errors).length === 0
    }

    const handleSubmit = () => {
        if (validateForm()) {
            const formatFormData = (({ passwordConfirm, ...rest }) => rest)(formData);
            onSubmit(formatFormData)
        }
    }

    return (
        <VStack p={10} bgColor='white' borderRadius='md' boxShadow='md' spacing={5} {...rest}>
            <FormControl isInvalid={formErrors.fullname} isRequired>
                <FormLabel fontSize='xs'>Nome completo</FormLabel>
                <Input
                    name="fullname"
                    onChange={(e) => handleChange('fullname', e.target.value)}
                    value={formData.fullname}
                    placeholder="Seu nome completo" />

                <FormErrorMessage>{formErrors.fullname}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formErrors.email} isRequired>
                <FormLabel fontSize='xs'>Email</FormLabel>
                <Input
                    name="email"
                    onChange={(e) => handleChange('email', e.target.value)}
                    value={formData.email}
                    placeholder="Digite seu email principal" />

                <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formErrors.password} isRequired>
                <FormLabel fontSize='xs'>Senha</FormLabel>
                <InputGroup size='md'>
                    <Input
                        pr='4.5rem'
                        name="password"
                        value={formData.password}
                        onChange={(e) => handleChange('password', e.target.value)}
                        type={show ? 'text' : 'password'}
                        placeholder='Senha de acesso'
                        isInvalid={formErrors.password}
                    />

                    <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onClick={handleClick}>
                            {show ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>

            <FormControl isInvalid={formErrors.passwordConfirm} isRequired>
                <FormLabel fontSize='xs'>Confirmar senha</FormLabel>
                <InputGroup size='md'>
                    <Input
                        pr='4.5rem'
                        name="passwordConfirm"
                        onChange={(e) => handleChange('passwordConfirm', e.target.value)}
                        value={formData.passwordConfirm}
                        type={show ? 'text' : 'password'}
                        placeholder='Confirme a senha'
                    />

                    <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onClick={handleClick}>
                            {show ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                </InputGroup>

                <FormErrorMessage>{formErrors.passwordConfirm}</FormErrorMessage>
            </FormControl>

            <FormControl>
                <ButtonGroup>
                    <Button colorScheme="teal" onClick={handleSubmit}>Registrar</Button>
                    <Button variant='ghost' onClick={handleClear}>Limpar</Button>
                </ButtonGroup>
            </FormControl>

            <VStack width='100%' mt={5}>
                <Link href='/login'>
                    <Text fontSize='xs'>Já tem conta? Fazer login!</Text>
                </Link>
            </VStack>
        </VStack>
    )
}

export default RegisterForm
import React, { useState } from "react"
import { FormControl, Input, VStack, Button, FormErrorMessage, useToast, InputGroup, InputRightElement, Text, Link } from "@chakra-ui/react"

function LoginForm({onSubmit}) {
    const [show, setShow] = useState(false)
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handleClick = () => setShow(!show)

    const validateForm = () => {
        const errors = {};
        if (!formData.email) {
          errors.email = 'Email é obrigatório';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          errors.email = 'Email inválido';
        }
    
        if (!formData.password) {
          errors.password = 'Senha é obrigatória';
        } else if (formData.password.length < 6) {
          errors.password = 'Senha deve ter pelo menos 6 caracteres';
        }
    
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var isValid = validateForm();
        if (isValid) {
          await onSubmit(formData)
        } 
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (formErrors[name]) {
          setFormErrors({ ...formErrors, [name]: '' });
        }
    };

    return (
        <VStack bg='white' p='50px' boxShadow='lg' minWidth='17%' borderRadius={10}>
            <FormControl mb={3} mt={6}>
                <Input 
                    name="email" 
                    type="email"
                    placeholder="Email de usuário"
                    value={formData.email}
                    onChange={(e) => {handleChange(e)}}
                    isInvalid={formErrors.email}
                    boxShadow='md'
                    isRequired
                    autoFocus 
                />
                <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            </FormControl>

            <FormControl>
                {/* <HStack spacing={8}>
                    <PinInput 
                    type="alphanumeric" 
                    name='password' 
                    value={formData.password} 
                    onChange={handlePassword}
                    isRequired>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack> */}
                
                <InputGroup size='md'>
                    <Input
                        pr='4.5rem'
                        name="password"
                        onChange={(e) => {handleChange(e)}}
                        type={show ? 'text' : 'password'}
                        placeholder='Senha de acesso'
                        isInvalid={formErrors.password}
                        boxShadow='md'
                    />
                    
                    <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formErrors.password}</FormErrorMessage>
            </FormControl>

            <FormControl>
                <FormErrorMessage></FormErrorMessage>
            </FormControl>

            <VStack width='100%' mt={5}>
                <Button onClick={async (e) => await handleSubmit(e)} width='100%' colorScheme="teal">Fazer login</Button>
                <Button width='100%' variant="outline">Resetar senha</Button>
                <Link href='/registrar' mt={3}>
                    <Text fontSize='xs'>Não tem conta? Registrar-se agora</Text>
                </Link>
            </VStack>
        </VStack>
    )
}

export default LoginForm
import { Helmet } from "react-helmet";
import { SimpleGrid } from "@chakra-ui/react";
import { SITE_NAME } from "../misc/siteConfig";

function Dashboard() {


    return (
        <>
            <Helmet>
                <title>{SITE_NAME} &#8208; Dashboard</title>
            </Helmet>
            <SimpleGrid columns={4}>
            </SimpleGrid>
        </>
    )

}

export default Dashboard;
import React from 'react';
import { Box, Heading, VStack, HStack, Text, Image, Table, Tbody, Tr, Td, TableCaption, Icon, Thead, Th } from '@chakra-ui/react';
import { MdFiberManualRecord } from "react-icons/md";

const CronScenes = ({ scenes }) => {
  return (
    <Box p={10} boxShadow='md' bgColor='white'>
      <Heading size="md">Cenas</Heading>

      <Table >
          <TableCaption>
              <HStack>
                  <Icon color='teal' as={MdFiberManualRecord} />
                  <Text>Gravado</Text>
                  <Icon color='red' as={MdFiberManualRecord} /> 
                  <Text>Gravar</Text>
              </HStack>
          </TableCaption>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Referência</Th>
              <Th>Descrição</Th>
              <Th>Conteúdo</Th>
              <Th>Locução</Th>
            </Tr>
          </Thead>
          <Tbody>
              {scenes.map( scene => (
                  <Tr key={scene._id}>
                      <Td>
                          {scene.recorded ? (
                              <Icon color='teal' as={MdFiberManualRecord} />
                          ) : (
                              <Icon color='red' as={MdFiberManualRecord} />
                          )}
                      </Td>
                      <Td> <Image src={scene.image} width='100px'/> </Td>
                      <Td> <Text>{scene.description}</Text> </Td>
                      <Td> <Text>{scene.content}</Text> </Td>
                      <Td> <Text>{scene.voiceover}</Text> </Td>
                  </Tr>
              ))}
          </Tbody>
      </Table>
    </Box>
  );
};

export default CronScenes;

import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'
import Cookies from 'js-cookie';

const loginUser = async (credentials) => {
    try {
        const response = await API.post('/user/login', JSON.stringify(credentials));
        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const refreshToken = async (token) => {
    try {
        const response = await API.post('/user/refreshToken', {token})
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

// cria uma assinatura
const createSubscription = async (userId, planId, payment) => {
    try {
        var response = await API.post(`/user/subscription/`, {userId: userId, planId: planId, payment})
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

// Checa validade da assinatura
const checkValidation = async (userId) => {
    try {
        var response = await API.get(`user/subscription/validity/${userId}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

// Cancela a assinatura
const cancelSubscription = async (userId) => {
    try {
        var response = await API.delete(`subscription/${userId}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

// renova a endDate da subscription
const renewSubscription = async (subscriptionId) => {
    try {
        var response = await API.put(`subscription/${subscriptionId}/`, {status: true})
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createUser = async (credentials) => {
    try {
        var register = await API.post('/user', JSON.stringify(credentials))
        return register.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updateUserById = async (_id, updates) => {
    try {
        var update = await API.put(`user/${_id}`, JSON.stringify(updates))
        return update.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updatePassword = async (_id, currentPassword, newPassword) => {
    try {
        var response = await API.put(`user/${_id}/password`, { currentPassword, newPassword })
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const deleteUserById = async (_id) => {
    try {
        var response = await API.delete(`user/${_id}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const validateToken = async () => {
    try {
        const response = await API.post('user/validate', {});
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            throw new ApiException(error.response.data.error);
        } else {
            throw new ApiException(error.message);
        }
    }
};

const logoutUser = async () => {
    try {
        const logOut = await API.post('user/logout', {refresh: Cookies.get('_auth_refresh')}, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('_auth')}`
            }
        });

        // Sucesso no logout
        return (logOut.data.message);
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error);
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message);
        }
    }
};

export {
    loginUser,
    validateToken,
    logoutUser,
    renewSubscription,
    createSubscription,
    checkValidation,
    cancelSubscription,
    // getUserById,
    deleteUserById,
    refreshToken,
    updatePassword,
    updateUserById,
    createUser
}
import { useState, useEffect, useCallback } from "react";

import { SimpleGrid, useDisclosure } from '@chakra-ui/react';
import { Box, HStack, Button, Input, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import CronCard from "./view/CronCard";
import CronModal from './cronModal';
import CronNoteModal from "./cronNoteModal";

import { useToastContext } from '../../../context/ToastContext'
import { deleteCronById, getCronsAll, addNoteToCron } from "../../../services/backend/CronService";
import CronNoteForm from "./cronNoteForm";


function ListCron() {
    const navigate = useNavigate()


    const [crons, setCrons] = useState([])
    const [selectedCron, setSelectedCron] = useState({
        title: '',
        timestart: new Date(),
        timend: new Date(),
        scenes: [],
        locations: [],
        equips: [],
        notes: [],
        script: { customer: { fullname: '' } },
        team: []
    })

    // Pesquisa e paginação
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1)

    const { showToast } = useToastContext()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: noteFormOpen, onOpen: noteOnOpen, onClose: noteFormClose } = useDisclosure()

    const fetchCrons = useCallback(async (bypass = false) => {

        try {

            var response = await getCronsAll({
                page: currentPage,
                searchQuery: searchTerm
            })

            if (bypass) {
                setCrons(response.docs)
            } else if (crons.length !== response.docs.length) {
                setCrons(response.docs)
            }

            setCurrentPage(response.page)
            setTotalPages(response.totalPages)

        } catch (error) {
            showToast({
                title: 'Problema de loading...',
                description: error.message,
                status: 'error'
            })
        }

    }, [showToast, crons, currentPage, searchTerm])

    const makeNote = (cron) => {
        setSelectedCron(cron)
        noteOnOpen()
    }

    const handleDelete = async (cron) => {
        if (!cron || !cron._id) {
            showToast({
                title: 'Nenhum cronograma selecionado',
                description: 'Clique em um cronograma para deletar!',
                status: 'error'
            })
            return
        }

        try {
            var responde = await deleteCronById(cron._id)
            onClose()
            showToast({
                title: cron.title || 'Deletado!',
                description: responde.message,
                status: 'success'
            })
            fetchCrons()
        } catch (error) {
            showToast({
                title: 'Error ao deletar cronograma',
                description: error.message,
                status: 'error'
            })
        }
    }

    const handleEdit = (cron) => {
        navigate(`/dashboard/cronograma/editar/${cron._id}`)
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleCreate = () => {
        navigate('/dashboard/cronograma/criar')
    }

    const handleView = (cron) => {
        setSelectedCron(cron)
        onOpen()
    }

    const handleAddNote = async (note) => {
        try {
            await addNoteToCron(selectedCron._id, note)
            noteFormClose()
            fetchCrons(true)
            showToast({
                title: 'Nota adicionada',
                description: 'Nota adicionada com sucesso',
                status: 'success'
            })
        } catch (error) {
            showToast({
                title: 'Error ao adicionar nota',
                description: error.message,
                status: 'error'
            })
        }
    }

    useEffect(() => {

        fetchCrons()

    }, [fetchCrons])

    return (
        <>
            <HStack mb={4} display="flex" p={4} mt={4} borderWidth="1px" borderRadius="md" alignItems="center" boxShadow='md' bgColor='white'>
                <Button
                    colorScheme="teal"
                    onClick={handleCreate}
                    leftIcon={<AddIcon />}>
                    Adicionar
                </Button>

                <HStack>

                    <Input placeholder="Pesquisar" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

                </HStack>
            </HStack>

            <SimpleGrid templateColumns="repeat(auto-fit, 350px)" gap={5} mt={10}>
                {crons.map((cron) => (
                    <CronCard
                        cron={cron}
                        key={cron._id}
                        boxShadow='md'
                        sx={{ borderWidth: '2px', borderColor: 'white' }}
                        _hover={{ borderColor: 'teal' }}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        view={handleView}
                        makenote={makeNote}
                    />
                ))}
            </SimpleGrid>

            {!crons && (
                <Box mt={4} display="flex" alignItems="center">
                    <Button
                        onClick={() => handlePageChange(currentPage - 1)}
                        isDisabled={currentPage === 1}
                    >
                        Anterior
                    </Button>
                    <Text mx={2}>{currentPage} / {totalPages}</Text>
                    <Button
                        onClick={() => handlePageChange(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                    >
                        Próximo
                    </Button>
                </Box>
            )}

            <CronModal cron={selectedCron} isOpen={isOpen} onClose={onClose} onDelete={handleDelete} onEdit={handleEdit} />
            <CronNoteModal isOpen={noteFormOpen} onClose={noteFormClose} cron={selectedCron}>
                <CronNoteForm onSubmit={handleAddNote}/>
            </CronNoteModal>
        </>
    )
}

export default ListCron;
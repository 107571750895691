import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Button, Center, Box } from "@chakra-ui/react";

import FormCheckout from "../components/dashboard/premium/checkout/formCheckout";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser'
import { createSubscription } from "../services/backend/UserService";

import {Link as RouterLink} from 'react-router-dom'

function Checkout() {
    const location = useLocation()
    const { from, plan } = location.state;
    const user = useAuthUser();
    const [deviceId, setDeviceId] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState({});

    useEffect(() => {
        setTimeout(() => {
            const id = window.deviceId;
            setDeviceId(id);
        }, 2000);
    }, []);

    const handleSubmit = async (data) => {
        try {
            data.device_id = deviceId;
            const response = await createSubscription(user._id, plan._id, data);
            setPaymentStatus(response.payment_status);
        } catch (error) {
            setPaymentStatus({ error: error.message });
        }
    }

    return (
        <Center height={'100vh'} width={'100vw'} bgColor={'gray.100'} flexDirection={'column'}>
            <Box width={'50%'} mb={2}>
                <Button as={RouterLink} to={from || '/dashboard/premium'} variant={'outline'}>Sair do Checkout</Button>
            </Box>
            <FormCheckout submit={handleSubmit} payment_status={paymentStatus} width={'50%'} plan={plan} />
        </Center>
    )
}

export default Checkout
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Text, useCheckboxGroup, NumberIncrementStepper, NumberDecrementStepper, FormLabel, FormControl, Input, Textarea, VStack, NumberInput, NumberInputField, NumberInputStepper, Select, CheckboxGroup, Checkbox, RadioGroup, Radio, Stack, FormErrorMessage } from "@chakra-ui/react";

function PlansForm({ onSubmit, planData, edit = false, handleEdit }) {
    const [formData, setFormData] = useState({
        _id: "",
        name: "",
        description: "",
        tools: [],
        frequency: 0,
        frequency_type: "months",
        repetitions: 0,
        price: 0,
        payment_types: [],
        freeTrial: {
            status: false,
            frequency: 0,
            frequency_type: "months"
        }
    })
    // Grupo de checkboxes para tools usando useCheckboxGroup
    const { value: toolsValue, setValue: setToolsValue } = useCheckboxGroup({
        defaultValue: formData.tools,
    });

    // Grupo de checkboxes para payment_types usando useCheckboxGroup
    const { value: paymentTypesValue, setValue: setPaymentTypesValue } = useCheckboxGroup({
        defaultValue: formData.payment_types,
    });

    const [formErrors, setFormErrors] = useState({})

    useEffect(() => {
        if (planData && edit) {
            setFormData({
                ...formData,
                ...planData,
                _id: planData?._id,
            });
            // Atualiza os checkboxes para tools e payment_types
            setToolsValue(planData?.tools || []);
            setPaymentTypesValue(planData?.payment_types || []);
        }
    }, [planData, edit]);

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            tools: toolsValue,
            payment_types: paymentTypesValue,
        }));
    }, [toolsValue, paymentTypesValue]);

    const NumberFieldChange = (value, name) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const basicOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const freeTrialChange = (e, name) => {
        setFormData({
            ...formData,
            freeTrial: {
                ...formData.freeTrial,
                [e.target?.name || name]: e.target?.value || e
            }
        })
    }

    const validateForm = () => {
        const errors = {}
        const paymentTypes = Object.values(formData.payment_types);
        const tools = Object.values(formData.tools);

        if (!paymentTypes.some(type => type)) {
            errors.payment_types = "Selecione pelo menos um tipo de pagamento";
        }
        
        if (!tools.some(tool => tool)) {
            errors.tools = "Selecione pelo menos uma ferramenta";
        }

        if (!formData.name) {
            errors.name = "Preencha o nome do plano"
        }

        if (!formData.description) {
            errors.description = "Preencha a descricão do plano"
        }

        if (!formData.price || formData.price < 1) {
            errors.price = "Preencha o valor do plano"
        }

        if (!formData.frequency || formData.frequency < 1) {
            errors.frequency = "Preencha a duração do plano"
        }

        if (!formData.frequency_type) {
            errors.frequency_type = "Selecione o tipo de duração do plano"
        }

        if (formData.freeTrial.status) {

            if (formData.freeTrial.frequency && formData.freeTrial.frequency < 1) {
                errors.freeTrial.frequency = "Preencha a duração da trial"
            }

            if (!formData.freeTrial.frequency_type) {
                errors.freeTrial.frequency_type = "Selecione o tipo de duração da trial"
            }
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return false
        }
        return true
    }

    const handleSubmit = () => {
        if (validateForm()) {
            if (edit) {
                handleEdit(formData)
            } else {
                onSubmit(formData)
            }
        }
    }

    return (
        <VStack gap={4}>
            <VStack boxShadow={"md"} gap={5} p={4} width={"100%"} borderRadius={"md"} alignItems={"start"}>

                <Text fontSize={"xl"} fontWeight={"bold"}>Informações Básicas</Text>

                <FormControl isInvalid={formErrors.name} isRequired>
                    <Input name="name" type="text" placeholder="Nome do plano" value={formData.name} onChange={basicOnChange} />
                    <FormErrorMessage>Preencha o nome do plano</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={formErrors.description}>
                    <Textarea name="description" type="text" placeholder="Descricão do plano" value={formData.description} onChange={basicOnChange} />
                </FormControl>

                <FormControl isRequired isInvalid={formErrors.tools}>
                    <FormLabel>Ferramentas</FormLabel>
                    <CheckboxGroup colorScheme="teal" value={toolsValue} onChange={setToolsValue}>
                        <Stack gap={8} direction={"row"}>
                            <Checkbox name="Roteiros" value="Roteiros">
                                <Text fontSize={"md"}>Roteiro</Text>
                            </Checkbox>
                            <Checkbox name="Cronogramas" value="Cronogramas">
                                <Text fontSize={"md"}>Cronogramas</Text>
                            </Checkbox>
                            <Checkbox name="Equipamentos" value="Equipamentos">
                                <Text fontSize={"md"}>Equipamentos</Text>
                            </Checkbox>
                            <Checkbox name="Clientes" value="Clientes">
                                <Text fontSize={"md"}>Clientes</Text>
                            </Checkbox>
                            <Checkbox name="Locacoes" value="Locacoes">
                                <Text fontSize={"md"}>Locações</Text>
                            </Checkbox>
                        </Stack>
                    </CheckboxGroup>
                    <FormErrorMessage>{formErrors.tools}</FormErrorMessage>
                </FormControl>
            </VStack>

            <Stack direction={["column", "row"]} gap={4} width={"100%"}>
                <VStack boxShadow={"md"} gap={5} p={6} width={"50%"} borderRadius={"md"} alignItems={"start"}>
                    <Text fontSize={"xl"} fontWeight={"bold"}>Assinatura</Text>

                    <FormControl isRequired isInvalid={formErrors.price}>
                        <FormLabel fontSize={"xs"}>Preço</FormLabel>
                        <NumberInput name="price" placeholder="Preço" value={formData.price} onChange={value => NumberFieldChange(value, "price")}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>

                        <FormErrorMessage fontFamily={'xs'}>* Obrigatório</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={formErrors.frequency}>
                        <FormLabel fontSize={"xs"}>Duração</FormLabel>
                        <NumberInput name="frequency" placeholder="Duração" value={formData.frequency} onChange={value => NumberFieldChange(value, "frequency")}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage fontFamily={'xs'}>{formErrors.frequency}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={formErrors.frequency_type} isRequired>
                        <FormLabel fontSize={"xs"}>Tipo de duração</FormLabel>
                        <Select name="frequency_type" placeholder="Tipo de duração" value={formData.frequency_type} onChange={basicOnChange}>
                            <option value="days">Dias</option>
                            <option value="months">Meses</option>
                            <option value="years">Anos</option>
                        </Select>
                        <FormErrorMessage fontFamily={'xs'}>* Obrigatório</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={formErrors.repetitions}>
                        <FormLabel fontSize={"xs"}>Repetições</FormLabel>
                        <NumberInput name='repetitions' value={formData.repetitions} onChange={value => NumberFieldChange(value, "repetitions")}>
                            <NumberInputField placeholder="Repetições" />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage fontFamily={'xs'}>* Obrigatório</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={formErrors.payment_types}>
                        <FormLabel fontSize={"xs"}>Opções de pagamento</FormLabel>
                        <CheckboxGroup colorScheme="teal" value={paymentTypesValue} onChange={setPaymentTypesValue}>
                            <Stack alignItems={"start"} gap={5} direction={"row"}>
                                <Checkbox name="credit_card" value="credit_card">
                                    <Text fontSize={"sm"}>Cartão de Credito</Text>
                                </Checkbox>
                                <Checkbox name="ticket" value="ticket">
                                    <Text fontSize={"sm"}>Boleto</Text>
                                </Checkbox>
                                <Checkbox name="pix" value="pix">
                                    <Text fontSize={"sm"}>Pix</Text>
                                </Checkbox>
                            </Stack>
                        </CheckboxGroup>
                        <FormErrorMessage fontFamily={'xs'}>* Obrigatório</FormErrorMessage>
                    </FormControl>
                </VStack>

                <VStack boxShadow={"md"} gap={5} p={6} width={"50%"} borderRadius={"md"} alignItems={"start"}>
                    <Text fontSize={"xl"} fontWeight={"bold"}>Teste Gratuito</Text>

                    <FormControl>
                        <RadioGroup onChange={e => freeTrialChange(e, "status")}>
                            <Stack direction={"row"}>
                                <Radio name="status" value="true" size={"lg"}>Ativo</Radio>
                                <Radio name='staus' value="false" size={"lg"} defaultChecked>Inativo</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl>

                    <FormControl isInvalid={formErrors.freeTrial?.frequency}>
                        <FormLabel fontSize={"xs"}>Duração do teste</FormLabel>
                        <NumberInput name="frequency" value={formData.freeTrial.frequency} onChange={e => freeTrialChange(e, "frequency")}>
                            <NumberInputField placeholder="Duração do teste" />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </FormControl>

                    <FormControl isInvalid={formErrors.freeTrial?.frequency_type}>
                        <FormLabel fontSize={"xs"}>Tipo de duração</FormLabel>
                        <Select name="frequency_type" placeholder="Tipo de duração" onChange={freeTrialChange} value={formData.freeTrial.frequency_type}>
                            <option value="days">Dias</option>
                            <option value="months">Meses</option>
                            <option value="years">Anos</option>
                        </Select>
                        <FormErrorMessage>* Obrigatório</FormErrorMessage>
                    </FormControl>
                </VStack>
            </Stack>

            <FormControl>
                <Button onClick={handleSubmit}>Salvar</Button>
            </FormControl>
        </VStack>
    )
}

export default PlansForm

import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'

const getCronsAll = async ({ page = 1, limit = 10, searchQuery }) => {
    try {
        const response = await API.get('/crons', {
            params: {
                page,
                limit,
                search: searchQuery
            }
        })

        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const getCronById = async (_id = '') => {

    if (!_id && typeof _id !== 'string') new ApiException('Nenhum ID encontrado')

    try {
        const response = await API.get(`/crons/${_id}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updateCronByid = async ({
    _id = '',
    title,
    timestart,
    timend,
    scenes,
    locations,
    equips,
    notes,
    script,
    team
}) => {
    try {

        const response = await API.put(`/crons/${_id}`, {
            title,
            timestart,
            timend,
            scenes,
            locations,
            equips,
            notes,
            script,
            team
        })

        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const deleteCronById = async (_id = '') => {
    try {
        const response = await API.delete(`/crons/${_id}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createCron = async ({
    title,
    timestart,
    timend,
    scenes,
    locations,
    equips,
    notes,
    script,
    team
}) => {
    try {

        const response = await API.post(`/crons`, {
            title,
            timestart,
            timend,
            scenes,
            locations,
            equips,
            notes,
            script,
            team
        })

        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const addNoteToCron = async (cronId, note) => {
    try {
        var response = await API.post(`crons/${cronId}/notes`, note)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const removeNoteFromCron = async (cronId, noteId) => {
    try {
        var response = await API.delete(`crons/${cronId}/notes/${noteId}`)
        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

export {
    getCronsAll,
    getCronById,
    addNoteToCron,
    removeNoteFromCron,
    deleteCronById,
    updateCronByid,
    createCron
}
import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Input,
	Select,
	FormControl,
	FormLabel,
	ModalFooter,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react';

const EquipmentForm = ({ equipment, onClose, onSubmit }) => {
	const [formData, setFormData] = useState({
		type: '',
		model: '',
		quantity: 1,
	});
	const [formError, setFormError] = useState({});

	const types = [
		{
			value: 'Câmera',
			label: 'Câmera'
		},
		{
			value: 'Microfone',
			label: 'Microfone'
		},
		{
			value: 'Lentes',
			label: 'Lentes'
		},
		{
			value: 'Gravador de Áudio',
			label: 'Gravador de Áudio'
		},
		{
			value: 'Estabilizador de Câmera',
			label: 'Estabilizador de Câmera'
		},
		{
			value: 'Tripé',
			label: 'Tripé'
		},
		{
			value: 'Guimbal',
			label: 'Guimbal'
		},
		{
			value: 'Softbox',
			label: 'Softbox'
		},
		{
			value: 'Refletor Led',
			label: 'Refletor Led'
		},
		{
			value: 'Refletor',
			label: 'Refletor'
		},
		{
			value: 'Tela Verde',
			label: 'Tela Verde'
		},
		{
			value: 'Refletor',
			label: 'Refletor'
		},
		{
			value: 'Tela Verde',
			label: 'Tela Verde'
		},
		{
			value: 'MicroSD',
			label: 'Cartão de Memória'
		}
	]

	useEffect(() => {
		if (equipment) {
			setFormData({
				_id: equipment._id,
				type: equipment.type,
				model: equipment.model,
				quantity: equipment.quantity,
			});
		}
	}, [equipment]);

	const validateForm = () => {
		const errors = {};
		if (!formData.type) {
			errors.type = 'Por favor, informe o tipo';
		}
		if (!formData.model) {
			errors.model = 'Por favor, informe o modelo';
		}
		setFormError(errors);
		return Object.keys(errors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleQuantity = (value) => {
		setFormData({
			...formData,
			quantity: value
		});
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validateForm()) {
			const action = equipment ? 'edit' : 'create'
			onSubmit({
				action,
				data: formData
			})
			onClose();
		}
	};

	return (
		<Box>
			<FormControl mb={4} isRequired isInvalid={formError.type}>
				<FormLabel>Tipo</FormLabel>
				<Select
					name="type"
					value={formData.type}
					onChange={handleChange}
					isRequired
				>
					<option>Selecione um tipo</option>
					{types.map((type) => (
						<option value={type.value}>{type.label}</option>
					))}
				</Select>
			</FormControl>

			<FormControl mb={4} isRequired isInvalid={formError.model}>
				<FormLabel>Modelo</FormLabel>
				<Input
					name="model"
					value={formData.model}
					placeholder='ex: Canon 5D Mark IV'
					onChange={handleChange}
					required
				/>
			</FormControl>

			<FormControl mb={4}>
				<FormLabel>Quantidade</FormLabel>
				<NumberInput min={1} value={formData.quantity} onChange={handleQuantity}>
					<NumberInputField
						name="quantity"
						inputMode='numeric'
						isRequired />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			</FormControl>

			<ModalFooter>
				<Button colorScheme="teal" onClick={handleSubmit}>
					Salvar
				</Button>
				<Button onClick={onClose} ml={3}>
					Cancelar
				</Button>
			</ModalFooter>
		</Box>
	);
};

export default EquipmentForm;

import { useCallback, useEffect, useState } from "react";
import SceneForm from "../../components/dashboard/cena/newCenaForm"
import { useNavigate, useParams } from "react-router-dom";
import { useToastContext } from "../../context/ToastContext";

import {
    getScenesAll,
    deleteSceneById,
    updateSceneById,
    createScene
} from '../../services/backend/ScenesService'

import { getScriptById } from "../../services/backend/ScriptService";
import { Heading, IconButton } from "@chakra-ui/react";
import { FaArrowAltCircleLeft } from "react-icons/fa";

function Scenes() {
    const { id } = useParams()
    const { showToast } = useToastContext()
    const navigate = useNavigate()

    const [scenesList, setScenesList] = useState([])
    const [script, setScript] = useState({})

    const handleSaveScenes = async (scenes) => {
        var errors = []

        if (scenes.length <= 0) {
            showToast({
                title: 'Nenhuma cena para salvar',
                description: 'Você precisa adicionar cenas para salvar',
                status: 'error'
            })
            return
        }

        for (const scene of scenes) {
            try {

                if(!scene._id) await createScene({...scene, script: id})
                else await updateSceneById({...scene, script: id})

            } catch (error) {
                errors.push(scene)
                showToast({
                    title: `Cena #${scene.order}`,
                    description: error.message,
                    status: 'error'
                })
            }
        }

        if (errors.length === 0) {
            fetchScenes(id)
            showToast({
                title: 'Salvo',
                description: 'Todas as cenas foram salvas com sucesso!',
                status: 'success'
            })
        }
    };

    const handleShare = () => {

    }

    const handleDeleteScene = async (scene) => {
        try {
            var response = await deleteSceneById(scene._id)
            fetchScenes(id)
            showToast({
                title: `Cena #${scene.order} deletada`,
                description: response.message,
                status: 'success'
            })
        } catch (error) {
            showToast({
                title: `Erro ao deletar #${scene.order} `,
                description: error.message,
                status: 'error'
            })
        }
    }

    const fetchScenes = useCallback(async (scriptId) => {

        try {
            var response = await getScenesAll({
                limit: 30,
                script: scriptId
            })

            var getScript = await getScriptById(scriptId)
            setScript(getScript)

            if (scenesList.length !== response.docs.length) setScenesList(response.docs)
        } catch (error) {
            showToast({
                title: 'Erro ao carregar cenas',
                description: error.message,
                status: 'error'
            })
        }

    }, [ scenesList, showToast])

    useEffect(() => {
        if (!id) navigate('/roteiro')
        fetchScenes(id)
    }, [id, fetchScenes, navigate])


    return (
        <>
        <Heading 
            p={4} 
            mb={5}  
            borderWidth="1px" 
            borderRadius="md" 
            fontSize='md' 
            boxShadow='md' 
            bgColor='white'>
                <IconButton icon={<FaArrowAltCircleLeft color="teal" />} variant='ghost' mr={3} onClick={() => navigate('/dashboard/roteiro')}/>
                {script.title}
        </Heading>
        <SceneForm onSubmit={handleSaveScenes} scenesList={scenesList} handleShare={handleShare} handleDelete={handleDeleteScene} />
        </>
    )
}

export default Scenes;
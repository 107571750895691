import { Box, Flex, VStack } from "@chakra-ui/react";
import { Image } from '@chakra-ui/react';

import logo from '../assets/images/logo.png';
import MenuLink from "../components/dashboard/MenuLinks";
import UserSession from "../components/dashboard/userSession";
import { Outlet } from "react-router-dom";

import PremiumBox from "../components/dashboard/premium/premiumBox";

function Layout() {

    return (
        <>
        <Flex height="100vh">
            <Box
                width="250px"
                bg="gray.700"
                height='100%'
                overflowY="auto"
                position="fixed"
                color="white"
                p={4}
                borderRightColor='teal'
                borderRightWidth='4px'
                boxShadow='lg'
            >
                <VStack>
                    <Image src={logo} width='150px' mt={10} mb={10} />

                    <MenuLink/>

                    <UserSession mt={50}/>
                </VStack>
                
            </Box>

            
            <Box flex="1" p={4} ml='250px' bgColor='gray.100' overflowY='auto'>
                <PremiumBox
                    bgColor='teal'
                    boxShadow='lg'
                    textColor='white'
                    zIndex={999}
                    p={4}
                    borderRadius={10}
                    position='fixed' 
                    bottom='30px' 
                    right='30px' />
                <Outlet/>
            </Box>
        </Flex>
        </>
    )

}

export default Layout;
import React from 'react';
import { Box, Heading, VStack, Text, SimpleGrid, GridItem, HStack, Tag } from '@chakra-ui/react';

const CronEquips = ({ equips, ...rest }) => {
  return (
    <Box  p={10} boxShadow='md' bgColor='white' {...rest}>
      <Heading size="md">Equipamentos</Heading>
      <SimpleGrid columns={3} spacing={4} mt={2}>
        {equips.map(equip => (
          <HStack key={equip._id} p={4} borderWidth={1} borderRadius="md" shadow="md" align='center' justify='center'>
            <Text fontSize="lg" fontWeight="bold">{equip.type} - {equip.model}</Text>
            <Tag fontSize="md" colorScheme='teal'>{equip.quantity}</Tag>
          </HStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default CronEquips;

import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Input,
	FormControl,
	FormLabel,
	Select,
	NumberInput,
	NumberInputField
} from '@chakra-ui/react';

const CustomerForm = ({ customer, onSubmit }) => {
	const [formData, setFormData] = useState({
		_id: '',
		fullname: '',
		email: '',
		phonenumber: '',
		type: ''
	});

	const [formError, setFormError] = useState({});

	useEffect(() => {
		if (customer) {
			setFormData(customer);
		}
	}, [customer]);

	const validateForm = () => {
		var errors = {};
		if (!formData.fullname) errors.fullname = 'Preencha o nome completo';
		if (!formData.type) errors.type = 'Selecione o tipo de cliente';
		setFormError(errors);
		return Object.keys(errors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
		setFormError((prev) => ({ ...prev, [name]: '' }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if(validateForm()) {
			onSubmit(formData);
		}
	};

	return (
		<Box as="form" onSubmit={handleSubmit}>
			<FormControl id="fullname" mb={4} isInvalid={formError.fullname} isRequired>
				<FormLabel>Nome Completo</FormLabel>
				<Input name="fullname" value={formData.fullname} onChange={handleChange}  />
			</FormControl>
			<FormControl id="email" mb={4}>
				<FormLabel>Email</FormLabel>
				<Input name="email" value={formData.email} onChange={handleChange}  />
			</FormControl>
			<FormControl id="phonenumber" mb={4}>
				<FormLabel>Telefone</FormLabel>
				<NumberInput value={formData.phonenumber}>
					<NumberInputField name="phonenumber" onChange={handleChange}  />
				</NumberInput>
			</FormControl>
			<FormControl id="type" mb={4} isInvalid={formError.type} isRequired>
				<FormLabel>Tipo</FormLabel>
				<Select name="type" value={formData.type} onChange={handleChange} >
					<option>Seleciona um tipo...</option>
					<option value="CNPJ">Pessoa Jurídica</option>
					<option value="CPF">Pessoa Física</option>
				</Select>
			</FormControl>
			<Button type="submit" colorScheme="teal">Salvar</Button>
		</Box>
	);
};

export default CustomerForm;

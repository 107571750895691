import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import { SITE_NAME } from "../misc/siteConfig";

import {checkPlanTools} from "../auth/userHelpers";

import ToolNotIncluded from "../components/dashboard/plan/toolNotIncluded";
import ListCron from "../components/dashboard/cron/listCron";
import CustomHeading from '../components/ui/Heading';

function Cron () {
    const user = useAuthUser();
    return (
        checkPlanTools(user, 'Cronogramas') ? (
            <Box>
                <Helmet>
                    <title>{SITE_NAME} &#8208; Cronogramas</title>
                </Helmet>
                <CustomHeading>Cronogramas</CustomHeading>
                <ListCron />
            </Box>
        ) : <ToolNotIncluded />
    )
}

export default Cron;
import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Input,
	FormControl,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	IconButton,
	VStack,
	HStack,
	Text
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';

import EquipmentForm from './EquipmentForm';
import { useToastContext } from '../../../context/ToastContext';
import { getEquipsAll, createEquip, updateEquipById, deleteEquipById } from '../../../services/backend/EquipamentsService';
import PopoverUi from '../../ui/Popover';

const ListEquipments = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedEquipment, setSelectedEquipment] = useState(null);
	const [currentPage, setCurrentPage] = useState()
	const [totalResults, setTotalResults] = useState()
	const [searchQuery, setSearchQuery] = useState('');
	const [equipaments, setEquipaments] = useState([])
	const { showToast } = useToastContext()

	const fetchEquips = useCallback(async (bypass = false) => {
		try {
			const response = await getEquipsAll({
				page: currentPage,
				search: searchQuery
			})

			if (bypass || equipaments.length !== response.docs.length) setEquipaments(response.docs)

			setTotalResults(response.totalPages)
			setCurrentPage(response.page)
		} catch (error) {
			showToast({
				title: 'Algo deu errado',
				description: error.message,
				status: 'error'
			})
		}
	}, [searchQuery, currentPage, showToast, equipaments])

	useEffect(() => {

		fetchEquips()

	}, [fetchEquips])

	const handleEdit = (equipment) => {
		setSelectedEquipment(equipment);
		onOpen();
	};

	const handleCloseModal = () => {
		setSelectedEquipment(null)
		onClose()
	}

	const handleDelete = async (_id) => {
		try {
			const response = await deleteEquipById(_id)
			fetchEquips()

			showToast({
				title: "Deletado!",
				description: response.message,
				status: 'success'
			})
		} catch (error) {
			showToast({
				title: "Error!",
				description: error.message,
				status: 'error'
			})
		}
	};

	const handleSubmit = async ({ action, data }) => {
		try {
			var response = {}

			switch (action) {
				case 'edit':
					response = await updateEquipById(data)
					break;
				default:
					response = await createEquip(data)
					break;
			}

			fetchEquips(true)
			showToast({
				title: `${data.model}`,
				description: response.message,
				status: 'success'
			})
		} catch (error) {
			showToast({
				title: `${data.model} - Error`,
				description: error.message,
				status: 'error'
			})
		}
	}

	return (
		<Box>
			<VStack spacing={4} align="stretch">
				<HStack p={4} mt={4} borderWidth="1px" borderRadius="md" boxShadow='md' bgColor='white'>
					<Button colorScheme="teal" leftIcon={<AddIcon />} mr={4} onClick={onOpen}>Adicionar</Button>
					<FormControl width={300}>
						<Input
							placeholder="Pesquisar..."
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</FormControl>
				</HStack>

				{equipaments.length > 0 && (
					<>
						<Table variant="simple" bgColor='white' borderRadius={10} boxShadow='lg' mt={6}>
							<Thead>
								<Tr>
									<Th>Tipo</Th>
									<Th>Modelo</Th>
									<Th>Quantidade</Th>
									<Th>Ações</Th>
								</Tr>
							</Thead>
							<Tbody>
								{equipaments.map(equipment => (
									<Tr key={equipment._id}>
										<Td>{equipment.type}</Td>
										<Td>{equipment.model}</Td>
										<Td>{equipment.quantity}</Td>
										<Td>
											<IconButton aria-label="Editar" icon={<EditIcon />} onClick={() => handleEdit(equipment)} />
											<PopoverUi
											element={<IconButton aria-label="Apagar" icon={<DeleteIcon />} ml={2} />}>
												<Text>Tem certeza que deseja apagar?</Text>
												<Button onClick={() => handleDelete(equipment._id)}>Sim</Button>
											</PopoverUi>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>

						{/* Placeholder para paginação */}
						<HStack justifyContent="space-between" mt={4}>
							<HStack>
								<Button isDisabled={(totalResults === currentPage)}>Anterior</Button>
								<Text>{`${currentPage} / ${totalResults}`}</Text>
								<Button isDisabled={(totalResults === currentPage)}>Próximo</Button>
							</HStack>
						</HStack>
					</>
				)}
			</VStack>
			<Modal isOpen={isOpen} onClose={handleCloseModal}>
				<ModalOverlay />
				<ModalContent p={30}>
					<ModalHeader>{selectedEquipment ? 'Editar Equipamento' : 'Adicionar Equipamento'}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<EquipmentForm equipment={selectedEquipment} onClose={onClose} onSubmit={handleSubmit} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default ListEquipments;

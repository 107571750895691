import React from 'react';
import { Box, Heading, Text, HStack, Card, Divider, CardHeader } from '@chakra-ui/react';

const CronDetails = ({ title, timestart, timend, customer }) => {
  return (
    <>
    <Card
      overflow='hidden'
      padding={10}
      boxShadow='md'
      variant='outline'>

      <CardHeader p={0}>
        <Text as='b' fontSize='lg'>Informações Básicas</Text>
        <Divider mt={5} mb={5}/>
      </CardHeader>

      <HStack>
        <Box fontSize="lg" width='50%'>
          <Text as='b' fontSize='sm'>Título</Text>
          <Text>{title}</Text>
        </Box>

        <Box fontSize="lg" width='50%'>
          <Text as='b' fontSize='sm'>Cliente</Text>
          <Text>{customer.fullname}</Text>
        </Box>

        <Box fontSize="lg" width='50%'>
          <Text as='b' fontSize='sm'>Data de Início</Text>
          <Text>{new Date(timestart).toLocaleString()}</Text>
        </Box>

        <Box fontSize="lg" width='50%'>
          <Text as='b' fontSize='sm'>Data de Término</Text>
          <Text>{new Date(timend).toLocaleString()}</Text>
        </Box>
      </HStack>
    </Card>
    </>
  );
};

export default CronDetails;

import React from "react";
import { IconButton, Link, ButtonGroup } from "@chakra-ui/react";

function SocialIcons({ options, ...props }) {
    return (
        <>
            {options && (
                <ButtonGroup {...props} variant='outline' colorScheme="teal">
                    {options.map((option, index) => (
                        <Link key={index} href={option.href}>
                            <IconButton icon={option.icon} />
                        </Link>
                    ))}
                </ButtonGroup>
            )}
        </>
    )
}

export default SocialIcons
import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'

const getScriptsAll = async ({page = 1, limit = 10, searchQuery, startDate, endDate}) => {
    try {
        const response = await API.get('/scripts', {
            params: {
                page,
                limit,
                search: searchQuery,
                startDate,
                endDate
            }
        })
        
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const getScriptById = async (_id = '') => {

    if(!_id && typeof _id !== 'string') throw new ApiException('Nenhum ID encontrado')

    try {
        const response = await API.get(`/scripts/${_id}`)
        
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updateScriptById = async ({
    _id,
    title,
    description = '',
    shared = false,
    customer,
}) => {
    try {

        const response = await API.put(`/scripts/${_id}`, {
            title,
            description,
            shared,
            customer,
        })

        if (response.data.error) throw new ApiException(response.data.error)

        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const deleteScriptById = async (_id = '') => {
    try {
        const response = await API.delete(`/scripts/${_id}`)

        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createScript = async ({
    title,
    description = '',
    shared = false,
    customer,
}) => {
    try {

        const response = await API.post(`/scripts`, {
            title,
            description,
            shared,
            customer
        })

        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

export {
    getScriptsAll,
    getScriptById,
    deleteScriptById,
    updateScriptById,
    createScript
}